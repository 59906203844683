import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["minPurchasesInput", "minPurchasesContainer", "stepSizeInput", "stepSizeContainer", "stepLength", "toggle", "manual", "automatic"]

  connect() {
    this.updateMode()
  }

  updateMode() {
    if (this.isAutomatic()) {
      this.switchAutomatic()
    } else {
      this.switchManual()
    }
  }

  switchAutomatic() {
    this.minPurchasesInputTarget.value = ''
    this.stepLengthTarget.value = '7' // 7 days
    this.stepSizeInputTarget.value = ''

    this.automaticTargets.forEach(target => target.classList.remove('hidden'))
    this.manualTargets.forEach(target => target.classList.add('hidden'))
  }

  switchManual() {
    this.automaticTargets.forEach(target => target.classList.add('hidden'))
    this.manualTargets.forEach(target => target.classList.remove('hidden'))
  }

  isAutomatic() {
    return this.toggleTarget.checked
  }

  showMinPurchasesNumberField() {
    this.minPurchasesContainerTarget.classList.remove('hidden')
  }

  hideMinPurchasesNumberField() {
    this.minPurchasesContainerTarget.classList.add('hidden')
    this.minPurchasesInputTarget.value = ''
  }

  showStepSizeNumberField() {
    this.stepSizeContainerTarget.classList.remove('hidden')
  }

  hideStepSizeNumberField() {
    this.stepSizeContainerTarget.classList.add('hidden')
    this.stepSizeInputTarget.value = ''
  }
}
