import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "settings", "dataSources", "analytics", 'shopify', "feedFields", "settingsButton", "dataSourcesButton", "analyticsButton", "feedFieldsButton", 'shopifyButton' ]

  connect() {
    this.selectSettings()
  }

  selectSettings() {
    this.settingsTarget.classList.remove("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.analyticsTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.remove("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
    this.shopifyButtonTarget.classList.add('btn-outline')
    this.shopifyTarget.classList.add('hidden')
  }

  selectDataSources() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.remove("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.remove("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
    this.shopifyButtonTarget.classList.add('btn-outline')
    this.shopifyTarget.classList.add('hidden')
  }

  selectAnalytics() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsTarget.classList.remove("hidden")
    this.analyticsButtonTarget.classList.remove("btn-outline")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
    this.shopifyButtonTarget.classList.add('btn-outline')
    this.shopifyTarget.classList.add('hidden')
  }

  selectFeedFields() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.remove("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.analyticsTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.remove("hidden")
    this.feedFieldsButtonTarget.classList.remove("btn-outline")
    this.shopifyButtonTarget.classList.add('btn-outline')
    this.shopifyTarget.classList.add('hidden')
  }

  selectShopify() {
    this.settingsTarget.classList.add("hidden")
    this.dataSourcesTarget.classList.add("hidden")
    this.feedFieldsTarget.classList.add("hidden")
    this.settingsButtonTarget.classList.add("btn-outline")
    this.dataSourcesButtonTarget.classList.add("btn-outline")
    this.analyticsButtonTarget.classList.add("btn-outline")
    this.analyticsTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("hidden")
    this.feedFieldsButtonTarget.classList.add("btn-outline")
    this.shopifyTarget.classList.remove('hidden')
    this.shopifyButtonTarget.classList.remove('btn-outline')
  }
}
