import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["maxLimitAddedValue", "minLimitAddedValue", "maxLimitPriceField", "minLimitPriceField", "maxLimitType", "minLimitType",  "minExceedsMaxMessage", "minLimitMissingMessage"]

  nilCeiling() {
    this.maxLimitAddedValueTarget.value = ''
  }

  checkMaxMinValues() {
    this.showMinExceedsMaxMessage(
      Number(this.maxLimitAddedValueTarget.value) <= Number(this.minLimitAddedValueTarget.value) &&
      this.maxLimitPriceFieldTarget.value == this.minLimitPriceFieldTarget.value &&
      this.maxLimitTypeTarget.value == this.minLimitTypeTarget.value
    )
  }

  showMinExceedsMaxMessage(show) {
    show ? this.minExceedsMaxMessageTarget.classList.remove('hidden') : this.minExceedsMaxMessageTarget.classList.add('hidden')
  }

  checkMinValueMissing() {
    this.showMinLimitMissingMessage(
      this.minLimitAddedValueTarget.value === ''
    )
  }

  showMinLimitMissingMessage(show) {
    show ? this.minLimitMissingMessageTarget.classList.remove('hidden') : this.minLimitMissingMessageTarget.classList.add('hidden')
  }
}
