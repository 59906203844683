import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  handleChange() {
    const form = this.inputTarget.closest('form')
    form.requestSubmit()
  }
}
